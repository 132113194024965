.timer {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  background-color: #eee;
  border: 3px solid #333;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.timer-last-10-seconds {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  background-color: #eee;
  border: 3px solid red;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: red;
  text-shadow: 0 0 20px rgba(255, 0, 0, 0.8);
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 20px rgba(255, 0, 0, 0.8);
  }
  to {
    text-shadow: 0 0 20px rgba(255, 0, 0, 0);
  }
}

.timer-container {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}
