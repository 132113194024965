.warning {
  position: fixed;
  top: 10px;
  left: 40%;
  right: 40%;
  color: red;
  border: 1px solid;
  text-align: center;
  border-radius: 11px;
  padding: 8px 0px;
}
