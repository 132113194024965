button {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  transition: all 0.3s ease;
}

button:hover {
  background-color: #3e8e41;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

button:disabled {
  background-color: #ccc;
  color: #666;
  box-shadow: none;
  cursor: not-allowed;
}

.show {
  display: inline-block;
}

.hide {
  display: none;
}
.pagination {
  width: 30px;
  height: 30px;
  background: rgb(100, 99, 99);
  text-align: center;
  margin-left: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  color: ghostwhite;
}
.mainpage {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0px;
  row-gap: 5px;
  justify-content: center;
}

.attempted {
  background: rgb(240, 240, 253);
  color: black;
}
