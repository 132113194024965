.CheckboxQuestion {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.CheckboxQuestion-question {
  font-weight: bold;
  margin-bottom: 4px;
}

.CheckboxQuestion-image {
  margin: 8px auto;
  text-align: center;
}

.CheckboxQuestion-images {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.CheckboxQuestion-options {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.CheckboxQuestion-options label {
  margin-left: 8px;
}

.CheckboxQuestion--no-image .CheckboxQuestion-image {
  display: none;
}

.checkboxs {
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  page-break-inside: avoid;
  word-wrap: break-word;
}
