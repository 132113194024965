.completed-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  background-color: #bed009;
}

.completed-container {
  padding: 100px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.completed-heading {
  font-size: 36px;
  margin-bottom: 10px;
}

.completed-message {
  font-size: 24px;
}
.fa-solid {
  font-size: 64px;
  color: green;
}
