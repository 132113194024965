.TimeInputQuestion {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  transition: background-color 200ms cubic-bezier(0, 0, 0.2, 1);
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 24px;
  page-break-inside: avoid;
  word-wrap: break-word;
}
