.mainRow {
  display: flex;
  justify-content: center;
}
.container {
  height: 100vh;
}
.questionHeader {
  display: flex;
  margin-bottom: 10px;
  padding: 5px;
}
.loginCol {
  padding: 10px;
  box-shadow: -3px 0px 20px 0px;
  background-color: ghostwhite;
  border-radius: 15px;
  margin: 80px 0px 0px 0px;
}
.success {
  justify-content: center;
  margin: 10px;
}
.mainHead {
  display: flex;
  justify-content: center;
}
.btnRow {
  justify-content: center;
  display: flex;
}
.verifyOtp {
  /* padding: 10px;
  margin: 10px; */
  display: flex;
  justify-content: space-between;
}

.declaration {
  width: 50%;
  margin-top: 15px;
  border: 1px solid #a19797;
  background: #e9e7f8;
  overflow: auto;
  /* height: 97vh; */
}

@media only screen and (max-width: 600px) {
  .declaration {
    width: 100%;
  }
}
.resend {
  margin-top: 10px;
  text-align: center;
}
.resendbtn {
  background: ghostwhite;
  color: black;
}
.confirmBox {
  text-align: center;
}
.termsAndCondition {
  text-align: center;
}
input {
  background-color: #e8f0db;
}
